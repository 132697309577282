/*desktop splash screen*/
.logosplash2{
  display: none !important;
}
.videopagecss {
    background: rgba(0, 0, 0, 0.75);
}
.mainsplashscreen{
  top: 15%;
  right: 0;
  height: 400px;
  width: 832px;
  bottom: 15%;
  left:0%;
  position: relative;
}
#videopage .modal-content{
  border-radius:0;
  position:relative;
  width:100%;
  border: none;
}
.mainsplashpopup{
  position: absolute;
  width: 832px;
  height: 400px;
  background: #F9F9F9;
}

.splashmobo{
  width: 100%;
  height: 400px;
  position: relative;
  background-color: #ffffff;
}
.splasdeskright{
  float: right;
  width: 50%;

}
.splashimagemobo{
  width: 428px;
  height: 400px;
  float: left;
  background: url(/images/desktopsplash1.png);
  background-size: cover;
}
.eatingfreefood{
  position: absolute;
  width: 335px;
  height: 60px;
  top: 51%;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  display: flex;
  align-items: flex-end;
  text-align: center;
  left:56%;
  color: #000;
}
.splashlogoname{
  position: absolute;
  top: 35%;
  left: 62%;
  width: 100%;
}
.logosplash{
 display: none!important;
}
.logosplashdesktop{
  height: 45px
}
/* 2end desktop image */
.splashmobo2{
  position: relative;
  width: 100%;
  height: 400px;
  background-color: #fff;
}

.splashimagemobo2{
  width: 428px;
  height: 400px;
  float: left;
  background: url(/images/splashScreenDesktop2.png);
  background-size: cover;
}
.splashimagemobolearn2{
  width: 428px;
  height: 400px;
  float: left;
  background: url(/images/splashScreenDesktopleaern2.png);
  background-size: cover;
}
.pickupdelivery{
    position: absolute;
    width: 38%;
    height: 60px;
    top: 26% !important;
    font-weight: normal;
    letter-spacing: 0.005em;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    left:58%;
    color: #000;
}
.learnmore{
  font-weight: 500;
  font-size: 16px;
  color: #000;
  z-index: 1;
  text-align: center;
  padding: 0px;
  padding-left: 30px;
  width: 253px;
  height: 40px;
  cursor: pointer;
}
.splashorder{
  font-weight: bold;
  font-style: normal;
  color: #000000;
  font-size: 36px;
  line-height: 43px;
}

/*end of 2nd screen */
/*3rd desktop screen */
.splashimagemobo3{
 width: 418px;
  height: 400px;
  float: left;
  background: url(/images/splashScreenDesktop4.png);
  background-size: cover;
}
.splashimagemobolearn3{
  width: 428px;
  height: 400px;
  float: left;
  background: url(/images/splashScreenDesktopleaern3.png);
  background-size: cover;
}
.creditterms{
  display: none;
}
/* end of 3rd screen */
/*4th desktop screen */
.splashimagevideomobo4{
 width: 543px;
  height: 400px;
  float: left;
  background: url(/images/videosplashimage.png);
  background-size: cover;
}
.splashimagemobo4{
  width: 424px;
  height: 400px;
  float: left;
  background: url(/images/splashScreenDesktop6.png);
  background-size: cover;
}
.logovideo{
  position: absolute;
  right: 8%;
  top: 25%;
  text-align: center;

}
.videopickupdelivery{
  position: absolute;
    width: 38%;
    height: 60px;
    top: 45%;
    font-weight: normal;
    letter-spacing: 0.005em;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    right: 0%;
    color: #000;
}
.videocommonbutton{

    height: 32px;
    padding: 3px;
    position: absolute;
    left:21%;
    text-align: center;
}
.splashimagemobolearn4{
 width: 466px;
  height: 450px;
  float: left;
  background: url(/images/splashScreenDesktop6.png);
  background-size: cover;
}

.commonbutton{
  width: 185px;
  height: 32px;
  padding: 3px;
  position: absolute;
  top:67%;
  left:65%;
}
/*end of 4th screen */
/* common css  for desktop and mobile splash screen */

/* css for splash learnmore  div */
.contentleanrmore{
  position: absolute;
  top:12%;

  left:58%;
}
.contentvideomore{
  position: absolute;
  width: 50%;
  margin-left: 9%;
  margin-top: 8%;
}
.cnt{
  font-style: normal;
  font-weight: bold;
  font-size:  24px;
  line-height: 29px;
}

.closescreen{
  padding-bottom: 20px;
  font-weight: bolder;
  font-size: 16px;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  color: #000000;
  position: absolute;
   width: 30px;
  height: 21.7px;
  right: 2%;
  top: 45px;
}
/* end */
.splashskip{
  position: absolute;
  width: 30px;
  height: 21.7px;
  right: 2%;
  top: 10px;
  z-index: 1;
  cursor: pointer;
  text-align: right;
  color: #000;
  font-size:15px;
}
/* overright slick slider css */
.slick-slider{
 margin-bottom: 0px !important;
 
}
.slick-prev:before, .slick-next:before{
  display: none;
}
 #mainsplashpopup button.slick-prev{
  position: absolute ;
  width: 42px ;
  height: 24px ;
  left: 55% ;
  top: 94%;
  font-weight: 500 ;
  font-size: 16px ;
   z-index: 999;
  color: #000 !important;
  background-color:transparent !important;
}
#mainsplashpopup button.slick-next {
  position: absolute ;
  width: 42px ;
  height: 24px ;
  right: 2% ;
  top: 94%;
  font-weight: 500 ;
  z-index: 999;
  font-size: 15px;
  color: #000 !important;
  background-color:transparent !important;
}
#mainsplashpopup ul.slick-dots button {
  background-color: transparent !important;
  color: #fff !important;
  font-size: 8px !important;
}
.slick-dots{
  bottom:2% !important;
  max-height: 30px;
  right: 9%;
  width: 20% !important;
}
.slick-dots li.slick-active button:before{
  color: #02843D!important;
  font-size: 12px !important;
}
.slick-dots li button:before{
  color: #D3D3D3!important;
  font-size: 12px !important;
  opacity: 2.25 !important;
}
.videosplashskip{
  position: absolute;
  width: 30px;
  height: 21.7px;
  right: 4%;
  display: none;
  top: 0px;
  z-index: 1;
  text-align: right;
  color: #000;
  font-size: 15px;
}
 /*end */
 /*mobile splash iphone 8screen */
 @media only screen
  and (min-width: 317px) and (max-width: 450px) and (max-height: 1076px) {
    .contentvideomore{
      position: absolute;
      width: 255px !important;
      margin-left: 16% !important;
      margin-top: 17% !important;
    }
    .videopickupdelivery{
      width: 100% !important;
      top:67% !important;
    }
    .logovideosplashdesktop{
      width: 220px !important;
    }
    .logovideo{
      right: 22% !important;
      top:6% !important;
    }

    .videocommonbutton{
      text-align: center !important;
      top: 77% !important;
      left: 1% !important;
    }
    .splashimagemobo2{
      background: unset;
      background-size: cover;
      position: absolute;
      width: 100%;
      height: auto;
      padding: 0;
      left: 0;
      top: -14%;

    }
    .splashimagemobo3{
      position: absolute;
      width: 100%;
      height: auto;
      top: -14%;
      padding: 0;
      background: unset;
      background-size: cover;
    }
    .splashimagevideomobo4{
      position: absolute;
      width: 96%;
      height: 48%;
      left:0%;
      top: 14%;
      padding: 0;
      background: url(/images/videosplashimagemobo.png);
      background-size: cover;
    }
    .splashimagemobo4{
      position: absolute;
      width: 100%;
      height: auto;
      top: -14%;
      padding: 0;
      background: unset;
      background-size: cover;
    }
    .slick-dots{
      left: initial !important;
      top: initial !important;
      top: 88% !important;
      right: 0;
      width: 100% !important;
      max-height: 30px;
      margin-top: 1rem;
    }
    ul.slick-dots.firstslick{
      right: 0%;
    }
    #mainsplashpopup button.slick-prev{
      top:92%;
    }
    #mainsplashpopup button.slick-next{
      top:92%;
    }
    .splashskip.removeskip{
    top: 5px !important;
    color: #000 !important;
    right: 5% !important;
    }
    .videosplashskip{
      display: none;
    }
    .contentleanrmore{
      position: absolute;
      top:22%;
      left: 0;
    }
    .mainsplashscreen{
      top: 0;
      right: 0;
      height: 100vh;
      width: 100%;
      bottom: 0;
      left: 0;
      position: relative;
    }
    .mainsplashpopup{
      position: absolute;
      width: 100% !important;
      height: 100vh !important;
      top: 0px !important;
      left: 0px !important;
    }
    .video-number{
      display: flex;
      justify-content: center;
    }
  /*firstscreen */
    .splashmobo {
      left: 0px !important;
      top: 0px !important;
      position: relative;
      width: 100%;
      height: 100vh;
    }
    .splashimagemobo{
      background: url(/images/_SplashScreenMobile1.png);
      background-size: cover;
      position: absolute;
      width: 100%;
      height: 100vh;
      left: 0;
      top: 0;
    }
    .splasdeskright{
      width: 100%;
      position: absolute;
      height: 100vh;
    }
    .eatingfreefood{
      position: absolute;
      height: 15vh;
      left: initial !important;
      top: initial;
      bottom: 26%;
      right: 0;
      margin: 0 auto;
      font-size: 18px;
      text-align: center;
      color: #fff;
      width: 100%;
    }
    .eatingfreefood .row {
      width: 100%;
    }
    .eatingfreefood .row .col-12 {
      margin-left: 0 !important;
    }
    .splashlogoname{
      position: absolute;
      left: initial;
      top: initial;
      bottom: 35%;
      right: 0;
      z-index: 1;
      margin: 0 auto;
      text-align: center;
    }
    .logosplashdesktop{
      display: none !important;
    }
    .logosplash{
      height: 55px;
      display: block !important;
      text-align: center;
      margin: auto;
      width: unset!important;
    }
    .logosplash2{
      height: 50px;
      display: block !important;
      margin-left: 75px;
      margin-top: 10px;
    }
  /* 2nd mobile splash page */
  .splashmobo2{
    left:0px !important;
    top:0px !important;
    position: relative;
    width: 100%;
    height: 100vh;
    }
    .slick-slide img{
    width: 100%;
    object-fit: cover;
  }
  .splashimagemobolearn2{
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background: #fff;
    background-size: cover;
  }
  .pickupdelivery{
    text-align: center;
    line-height: 27px;
    height: 44px;
    left: initial;
    top: initial;

    top: 50% !important;
    right: 0;
    font-size: 16px;
    word-break: break-word;
    width: 100%;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    color: #000;
  }
  .splashorder{
    left: 0 !important;
    text-align: center;
    color: #000000;
    font-size: 30px;
  }
  .learnmore{
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #000;
    width: 100% ;
    z-index: 1;
    margin-top: 0%;
    background: transparent !important;
    border-radius: none !important;
    padding: 0;
  }
    .splashimagemobolearn3{
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background: #fff;
    background-size: cover;
  }
  .creditterms{
    text-align: center;
    text-align: center;
    word-wrap: normal;
    width: 100%;
    font-size: 10px;
    padding-right: 20px;
   }
/* end of 3rd screen */
  /*4th mobilescreen */

  .splashimagemobolearn4{
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background: #fff;
    background-size: cover;
  }
  .commonbutton{
    left: initial !important;
    top: 77% !important;

    right: 0;
    width: 100%;
    text-align: center;
    font-weight: 600;
    padding-top: 6px !important;
    height: 36px !important;
    cursor: pointer;
    position: absolute;
  }
  .commonbutton .btn-success{
    height: 36px !important;
  }
  /*end */
  .slick-next{
    right: 5% !important;
    color: #000 !important;
  }
  .slick-prev{
     color: #000 !important;
     left: 4% !important;
  }

  .splashskip{
    top: 15px !important;
    color: #fff !important;
    right: 5% !important;
  }
  .closescreen{
    top: 18% !important;
    color: #000 !important;
    right: 10% !important;
  }
 .cnt{
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 43px;
  }
  .creditterms{
    margin-top: 1rem!important;
  }
  #seeAVideo {
    margin-top: 0;
  }
}
@media only screen
  and (min-width: 451px) and (max-width: 519px) and (max-height: 1076px) {
    .contentvideomore{
      position: absolute;
      width: 267px !important;
      margin-left: 16% !important;
      margin-top: 17% !important;
    }

    .videopickupdelivery{
      width: 100% !important;
      top:66% !important;
    }
    .logovideosplashdesktop{
      width: 220px !important;
    }
    .logovideo{
      right: 22% !important;
      top:6% !important;
    }

    .videocommonbutton{
      text-align: center !important;
      top: 77% !important;
      left: 1% !important;
    }
    .splashimagemobo2{
      background: unset;
      background-size: cover;
      position: absolute;
      width: 100%;
      height: auto;
      padding: 0;
      left: 0;
      top: -14%;

    }
    .splashimagemobo3{
      position: absolute;
      width: 100%;
      height: auto;
      top: -14%;
      padding: 0;
      background: unset;
      background-size: cover;
    }
    .splashimagevideomobo4{
      position: absolute;
      width: 96%;
      height: 48%;
      left:0%;
      top: 14%;
      padding: 0;
      background: url(/images/videosplashimagemobo.png);
      background-size: cover;
    }
    .splashimagemobo4{
      position: absolute;
      width: 100%;
      height: auto;
      top: -14%;
      padding: 0;
      background: unset;
      background-size: cover;
    }
    .slick-dots{
      left: initial !important;
      top: initial !important;
      top: 85% !important;
      right: 0;
      width: 100% !important;
      max-height: 30px;
      margin-top: 1rem;
    }
    ul.slick-dots.firstslick{
      right: 0%;
    }
    #mainsplashpopup button.slick-prev{
      top:92%;
    }
    #mainsplashpopup button.slick-next{
      top:92%;
    }
    .splashskip.removeskip{
    top: 5px !important;
    color: #000 !important;
    right: 5% !important;
    }
    .videosplashskip{
      display: none;
    }
    .contentleanrmore{
      position: absolute;
      top:22%;
      left: 0;
    }
    .mainsplashscreen{
      top: 0;
      right: 0;
      height: 100vh;
      width: 100%;
      bottom: 0;
      left: 0;
      position: relative;
    }
    .mainsplashpopup{
      position: absolute;
      width: 100% !important;
      height: 100vh !important;
      top: 0px !important;
      left: 0px !important;
    }
    .video-number{
      display: flex;
      justify-content: center;
    }
  /*firstscreen */
    .splashmobo {
      left: 0px !important;
      top: 0px !important;
      position: relative;
      width: 100%;
      height: 100vh;
    }
    .splashimagemobo{
      background: url(/images/_SplashScreenMobile1.png);
      background-size: cover;
      position: absolute;
      width: 100%;
      height: 100vh;
      left: 0;
      top: 0;
    }
    .splasdeskright{
      width: 100%;
      position: absolute;
      height: 100vh;
    }
    .eatingfreefood{
      position: absolute;
      height: 15vh;
      left: initial !important;
      top: initial;
      bottom: 26%;
      right: 0;
      margin: 0 auto;
      font-size: 18px;
      text-align: center;
      color: #fff;
      width: 100%;
    }
    .eatingfreefood .row {
      width: 100%;
    }
    .eatingfreefood .row .col-12 {
      margin-left: 0 !important;
    }
    .splashlogoname{
      position: absolute;
      left: initial;
      top: initial;
      bottom: 35%;
      right: 0;
      z-index: 1;
      margin: 0 auto;
      text-align: center;
    }
    .logosplashdesktop{
      display: none !important;
    }
    .logosplash{
      height: 55px;
      display: block !important;
      text-align: center;
      margin: auto;
      width: unset!important;
    }
    .logosplash2{
      height: 50px;
      display: block !important;
      margin-left: 75px;
      margin-top: 10px;
    }
  /* 2nd mobile splash page */
  .splashmobo2{
    left:0px !important;
    top:0px !important;
    position: relative;
    width: 100%;
    height: 100vh;
    }
    .slick-slide img{
    width: 100%;
    object-fit: cover;
  }
  .splashimagemobolearn2{
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background: #fff;
    background-size: cover;
  }
  .pickupdelivery{
    text-align: center;
    line-height: 27px;
    height: 44px;
    left: initial;
    top: initial;

    top: 50% !important;
    right: 0;
    font-size: 16px;
    word-break: break-word;
    width: 100%;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    color: #000;
  }
  .splashorder{
    left: 0 !important;
    text-align: center;
    color: #000000;
    font-size: 30px;
  }
  .learnmore{
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #000;
    width: 100% ;
    z-index: 1;
    margin-top: 0%;
    background: transparent !important;
    border-radius: none !important;
    padding: 0;
  }
    .splashimagemobolearn3{
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background: #fff;
    background-size: cover;
  }
  .creditterms{
    text-align: center;
    text-align: center;
    word-wrap: normal;
    width: 100%;
    font-size: 10px;
    padding-right: 20px;
   }
/* end of 3rd screen */
  /*4th mobilescreen */

  .splashimagemobolearn4{
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background: #fff;
    background-size: cover;
  }
  .commonbutton{
    left: initial !important;
    top: 77% !important;

    right: 0;
    width: 100%;
    text-align: center;
    font-weight: 600;
    padding-top: 6px !important;
    height: 36px !important;
    cursor: pointer;
    position: absolute;
  }
  .commonbutton .btn-success{
    height: 36px !important;
  }
  /*end */
  .slick-next{
    right: 5% !important;
    color: #000 !important;
  }
  .slick-prev{
     color: #000 !important;
     left: 4% !important;
  }

  .splashskip{
    top: 15px !important;
    color: #fff !important;
    right: 5% !important;
  }
  .closescreen{
    top: 18% !important;
    color: #000 !important;
    right: 10% !important;
  }
 .cnt{
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 43px;
  }
  .creditterms{
    margin-top: 1rem!important;
  }
  #seeAVideo {
    margin-top: 0;
  }
}
@media only screen
    and (min-width: 375px) and (max-width: 422px)
  and (min-height: 738px) and (max-height: 955px) {
  .splashimagevideomobo4{
    position: absolute;
    width: 96% !important;
    height: 40% !important;
    top: 14%;
    padding: 0;
    background: url(/images/videosplashimagemobo.png);
    background-size: cover;
  }
  .contentvideomore{
    position: absolute;
    width: 267px !important;
    margin-left: 16% !important;
    margin-top: 17% !important;
  }

  .videopickupdelivery{
    width: 100% !important;
    top:58% !important;
  }
  .logovideosplashdesktop{
    width: 220px !important;
  }
  .logovideo{
    right: 18% !important;
    top:7% !important;
  }

  .videocommonbutton{
    text-align: center !important;
    left:1% !important;
  }
  .splashskip.removeskip{
    top: 5px !important;
    color: #000 !important;
    right: 5% !important;
  }
  .videosplashskip{
    display: none;
  }
  .contentleanrmore{
    position: absolute;
    top:22%;
    left: 0;
  }
  .mainsplashscreen{
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    bottom: 0;
    left: 0;
    position: relative;
  }
  .mainsplashpopup{
    position: absolute;
    width: 100% !important;
    height: 100vh !important;
    top: 0px !important;
    left: 0px !important;
  }
  /*firstscreen */
  .splashmobo {
    left: 0px !important;
    top: 0px !important;
    position: relative;
    width: 100%;
    height: 100vh;
  }
  .splashimagemobo{
    background: url(/images/_SplashScreenMobile1.png);
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
  }
  .splasdeskright{
    width: 100%;
    position: absolute;
    height: 100vh;
  }
  .eatingfreefood{
    position: absolute;
    height: 15vh;
    left: initial !important;
    top: initial;
    bottom: 26%;
    right: 0;
    margin: 0 auto;
    font-size: 18px;
    text-align: center;
    color: #fff;
    width: 100%;
  }
  .eatingfreefood .row {
    width: 100%;
  }
  .eatingfreefood .row .col-12 {
    margin-left: 0 !important;
  }
  .splashlogoname{
    position: absolute;
    left: initial;
    top: initial;
    bottom: 35%;
    right: 0;
    z-index: 1;
    margin: 0 auto;
    text-align: center;
  }
  .logosplashdesktop{
    display: none !important;
  }
  .logosplash{
    height: 55px;
    display: block !important;
    text-align: center;
    margin: auto;
    width: unset!important;
  }
  .logosplash2{
    height: 50px;
    display: block !important;
    margin-left: 75px;
    margin-top: 10px;
  }
  /* 2nd mobile splash page */
  .splashmobo2{
    left:0px !important;
    top:0px !important;
    position: relative;
    width: 100%;
    height: 100vh;
  }
  .splashimagemobo2{
    background: url(/images/SplashScreenMobile2.png);
    background-size: cover;
    position: absolute;
    width: 100%;
    height: auto;
    padding: 0;
    left: 0;
    top: -6%;

  }
  .slick-slide img{
    width: 100%;
    object-fit: cover;
  }
  .splashimagemobolearn2{
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background: #fff;
    background-size: cover;
  }
  .pickupdelivery{
    text-align: center;
    line-height: 27px;
    height: 44px;
    left: initial;
    top: initial;

    top: 50% !important;
    right: 0;
    font-size: 16px;
    word-break: break-word;
    width: 100%;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    color: #000;
  }
  .splashorder{
    left: 0 !important;
    text-align: center;
    color: #000000;
    font-size: 30px;
  }
  .learnmore{
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #000;
    width: 100% ;
    z-index: 1;
    margin-top: 0%;
    background: transparent !important;
    border-radius: none !important;
    padding: 0;
  }
  /*end of 2nd screen */
  /*3rd mobilescreen */

  .splashimagemobo3{
    position: absolute;
    width: 100%;
    height: auto;
    top: -6%;
    padding: 0;
    background: unset;
    background-size: cover;
  }
  .splashimagemobolearn3{
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background: #fff;
    background-size: cover;
  }
  .creditterms{
    text-align: center;
    text-align: center;
    word-wrap: normal;
    width: 100%;
    font-size: 10px;
    padding-right: 20px;
   }
/* end of 3rd screen */
  /*4th mobilescreen */
 .splashimagemobo4{
    position: absolute;
    width: 100%;
    height: auto;
    top: -6%;
  padding: 0;
    background: url(/images/SplashScreenMobile6.png);
    background-size: cover;
  }
  .splashimagemobolearn4{
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background: #fff;
    background-size: cover;
  }
  .commonbutton{
    left: initial !important;
    top: 77% !important;

    right: 0;
    width: 100%;
    text-align: center;
    font-weight: 600;
    padding-top: 6px !important;
    height: 36px !important;
    cursor: pointer;
    position: absolute;
  }
  .commonbutton .btn-success{
    height: 36px !important;
  }
  /*end */
  .slick-next{
    right: 5% !important;
    color: #000 !important;
  }
  .slick-prev{
     color: #000 !important;
     left: 4% !important;
  }
  .slick-dots{
    left: initial !important;
    top: initial !important;
    top: 75% !important;
    right: 0;
    width: 100% !important;
    max-height: 30px;
  }
  #mainsplashpopup button.slick-prev{
    top:84%;
  }
  #mainsplashpopup button.slick-next{
    top:84%;
  }
  .splashskip{
    top: 15px !important;
    color: #fff !important;
    right: 5% !important;
  }
  .closescreen{
    top: 18% !important;
    color: #000 !important;
    right: 10% !important;
}
 .cnt{
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 43px;
  }
  .creditterms{
    margin-top: 1rem!important;
  }
  #seeAVideo {
    margin-top: 0;
  }
}
/* mobile rotate */
/* for ios phone 8 */
@media only screen
    and (min-width: 414px)
    and (min-height: 635px)
    and (max-height: 737px)  {

      .videosplashskip{
        display: none;
      }
      .pickupdelivery{
        bottom: 30%;
      }
      .creditterms{
       margin-top: .5rem!important;
      }
}
/* landscape the  phone */

@media only screen and (min-device-width : 517px) and (max-device-width : 1024px) and (orientation : landscape) {

   .videosplashskip{
    display: block;
  }
  .mainsplashscreen{
    top: 10px;
    right: 0;
    height: 100vh;
    width: 100%;
    bottom: 0;
    left: 0;
    position: relative;
  }
  .mainsplashpopup{
    top: 0px ;
    left: 0%;
    height: 360px;
  }
  .splashmobo{
    height: 360px;
  }
  .splashimagemobo{
    width: 375px;
    height: 360px;
  }
  .splashlogoname{
    left: 50%;
  }
  .eatingfreefood{
    left: 50%;
    font-size: 16px
  }
  /*2nd screen */
  .splashmobo2{
    height: 360px;
  }
  .splashimagemobo2{
    width: 375px;
    height: 360px;
  }
  .videopickupdelivery{
    left: 46%;
    font-size: 16px;
    top: 39% !important;
    width:37%;
  }
  .pickupdelivery{
    left: 46%;
    font-size: 16px;
    top: 21% !important;
    width:37%;
  }
  .commonbutton{
   left: 55% !important; 
  }
  .logovideo{
    right: 21%;
  }
  .videocommonbutton{
    left: 6%;
  }
  .contentvideomore{
    width: 38% !important;
  }
  ul.slick-dots.firstslick{
    right: 23% !important;
  }
  .splashimagemobolearn2{
    width: 375px;
    height: 360px;
  }
  .contentleanrmore {
    top: 8%;
    left: 42%;
    width: 34%;
  }
  .splashimagemobo3{
    width: 375px;
    height: 360px;
  }
  .splashimagemobolearn3{
    width: 375px;
    height: 360px;
  }
  .splashimagemobo4{
    width: 375px;
    height: 360px;
  }
  .splashimagevideomobo4{
    width: 375px;
    height: 360px;
  }
  .learnmore{
    left: 0%;
  }
  #mainsplashpopup button.slick-next{
    right: 12%;
  }
  #mainsplashpopup button.slick-prev{
    left: 47%;
  }
  .commonbutton{
    height: 32px;
  }
  .splashskip{
    right: 21%;
    top:11px;
  }
  .slick-dots{
    right: 24%;
  }
}
@media screen and (max-width: 480px) {
   .activevideo{height: 31px !important;width: 12px !important }
    .inactivevideo{
      height: 22px !important;
      width: 12px !important;
    }
    .pagevideo{
      margin-left: 46% !important;
    }
  }